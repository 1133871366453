html, body {
  min-height: 100%;
  height: 100%;
}

#viewport > * > md-content {
  background: transparent !important;
}

#sidebar {
  background: #222;
  color: white;
  width: 300px;
}

#sidebar .logo-area {
  padding-left: 20px;
  padding-top: 20px;
}

#sidebar .logo-area img {
  max-height: 80px;
}

#sidebar md-content {
  background: transparent !important;
}

#sidebar section {
  padding-left: 25px;
  margin-top: 50px;
  margin-bottom: 10px;
  color: #ccc;
  opacity: 0.5;
}

#sidebar .menu-item {
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

#sidebar .menu-item.active {
  background-color: #495c66;
}

#sidebar .menu-item[disabled] {
  opacity: 0.4;
}

#sidebar .menu-item md-icon {
  width: auto;
  height: auto;
  font-size: 40px;
  color: #ccc;
  margin: 0 17px 0 3px;
}

#sidebar .menu-item span {
  color: #ccc;
}

#sidebar team-working-status .menu-item {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

#sidebar team-working-status .menu-item .avatar-status {
  margin-left: 0;
}

#sidebar .footer {
  background-color: black;
  margin: 0;
  padding: 15px 10px;
}

#sidebar .footer .avatar {
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

#sidebar .footer md-icon {
  color: #ccc;
}

.editable-wrap {
  width: 100%;
}

.spacer {
  margin-bottom: 6px;
}

.small-input {
  font-size: 14px;
}

.separator {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.aligned {
  vertical-align: middle;
}

.proposal-toolbar {
  background-color: #e6e6e6 !important;
}

md-select {
  margin: 0px;
}

.table-list {
  border-collapse: collapse;
}

.table-list th {
  color: #495c66;
}

.table-list th, .table-list td {
  vertical-align: middle;
  padding: 7px 0px;
}

.table-list md-checkbox {
  margin: 0px;
}

.list-filters md-select {
  margin: 0px;
  text-align: center;
}

md-input-container {
  margin: 2px;
}

md-input-container .md-errors-spacer {
  display: none;
}

.md-nav-item:hover span {
  color: white;
}

.md-nav-item .md-active {
  color: black !important;
}

.md-nav-bar md-nav-ink-bar {
  background-color: black !important;
}

.new-comment-area {
  background-color: white;
  border-top: solid 2px #eee;
  padding: 5px 20px;
  padding-right: 60px;
  position: relative;
}

.new-comment-area img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.new-comment-area md-input-container {
  margin: 0;
  margin-left: 15px;
}

.new-comment-area md-input-container label {
  -webkit-transform: none !important;
          transform: none !important;
  top: 10px;
  left: 0;
  bottom: 0;
}

.new-comment-area md-input-container .md-errors-spacer {
  display: none;
}

.new-comment-area md-input-container textarea {
  border: 0;
  outline: none;
  margin-top: 2px;
}

.new-comment-area md-input-container .md-resize-handle {
  display: none;
}

.new-comment-area md-input-container.md-input-has-value label {
  display: none;
}

.new-comment-area .send-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 0;
  height: 100%;
  border-radius: 0;
  width: 50px;
  box-shadow: none !important;
}

.proposal-client {
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 5px;
}

.block-border {
  padding: 10px;
  margin-right: 15px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
}

h2 {
  margin: 5px;
}

files-display {
  position: relative;
  background-color: white;
  border-top: solid 1px #eee;
  padding: 0 5px;
}

files-display .file-preview {
  min-width: 130px;
  height: 30px;
  float: left;
  overflow: hidden;
  background-color: rgba(34, 34, 34, 0.05);
  margin: 10px;
  line-height: 30px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  line-break: strict;
  vertical-align: middle;
}

files-display .file-preview a {
  font-size: 12px;
  color: #666;
  text-decoration: none;
  text-align: center;
  padding: 0 10px;
}

files-display .file-preview a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

files-display .file-preview a:hover md-icon {
  color: white !important;
  opacity: 1 !important;
}

files-display .file-preview a[download] {
  border-left: solid 1px #eee;
  padding-left: 5px;
  margin-right: -5px;
}

files-display .file-preview a[download] md-icon {
  font-size: 18px;
  height: auto;
  width: auto;
  max-width: none;
  max-height: none;
  padding-top: 2px;
  opacity: 0.5;
}

.file-preview {
  min-width: 160px;
  height: 30px;
  overflow: hidden;
  background-color: white;
  margin: 5px;
  line-height: 30px;
  line-break: strict;
  vertical-align: middle;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}

.file-preview a {
  padding: 5px;
}

.file-preview a textarea {
  font-size: 12px;
  color: #666;
  text-decoration: none;
  text-align: center;
  border: 0;
  outline: none;
  overflow: hidden;
  cursor: default;
  position: relative;
  width: 100%;
  vertical-align: middle;
}

.file-preview a textarea:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: white;
  cursor: pointer;
}

.file-preview a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.file-preview a:hover md-icon {
  color: white !important;
  opacity: 1 !important;
}

.file-preview a[download] md-icon {
  font-size: 20px;
}

.edit-description .btn-group {
  display: inline-block;
}

.edit-description .btn-group button {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border: solid 1px #cccccc;
  background-color: #ffffff !important;
  color: #333333;
  float: left;
  line-height: 20px;
  padding: 6px 12px;
  vertical-align: middle;
  opacity: 0.65;
}

.edit-description .btn-group #toolbarWC {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border: solid 1px #cccccc;
  background-color: #ffffff !important;
  color: #333333;
  float: left;
  line-height: 20px;
  padding: 6px 12px;
  vertical-align: middle;
  opacity: 0.65;
  pointer-events: none;
}

.edit-description .btn-group #toolbarCC {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border: solid 1px #cccccc;
  background-color: #ffffff !important;
  color: #333333;
  float: left;
  line-height: 20px;
  padding: 6px 12px;
  vertical-align: middle;
  opacity: 0.65;
  pointer-events: none;
}

clock-bar {
  min-height: 80px;
  padding: 0 10px 0 20px;
}

clock-bar task-preview {
  border-bottom: 0;
  width: 100%;
}

projects-list md-list {
  padding: 0;
}

projects-list md-list md-divider:last-child {
  display: none;
}

team-working-status {
  color: #aaa;
}

team-working-status .avatar-status {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

team-working-status .user-name {
  font-weight: bold;
}

team-working-status .working .avatar-status {
  border: solid 3px #495c66;
}

team-working-status .paused .avatar-status {
  border: solid 3px #ff6400;
}

team-working-status .stopped .avatar-status {
  border: solid 3px #ccc;
  opacity: 0.3;
}

team-working-status .active.working .avatar-status {
  border-color: #95a9b3;
}

project-details .title-input {
  width: 100%;
  margin-top: 20px;
}

.image-preview {
  margin: 5px;
  border: 1px solid #cccccc;
}

md-list-item:before {
  display: none;
}

task-preview {
  border-bottom: solid 1px #ececec;
}

task-preview > div {
  min-height: 60px;
  height: 100%;
  margin: 0 !important;
}

task-preview > div *:first-child {
  margin-left: 0;
}

task-preview > div .md-button {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

task-preview .closed {
  opacity: 0.5;
}

task-preview .closed .task-name {
  text-decoration: line-through;
}

task-preview .task-name {
  padding: 10px;
  outline-color: #495c66;
}

task-preview md-menu {
  margin: 0 !important;
}

task-preview md-input-container .md-select-value {
  border-bottom: 0;
}

task-preview md-input-container .md-select-value .md-select-icon {
  display: none;
}

task-preview md-input-container.md-input-has-value label {
  display: none;
}

task-preview md-input-container md-icon {
  opacity: 0.7;
  font-size: 16px !important;
  padding-top: 5px;
  padding-right: 0;
  width: auto;
}

md-option.hide-icons md-icon {
  display: none !important;
}

task-preview .task-user-select {
  width: 50px;
}

user-badge.task-user-select-user-badge h1 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

user-badge.task-user-select-user-badge img {
  max-width: 30px;
  max-height: 30px;
}

task-preview .task-user-select .md-select-value h1 {
  display: none;
}

task-preview .task-user-select .md-select-value img {
  margin-left: 5px;
}

task-preview .start-working-btn {
  visibility: hidden;
  padding-left: 0;
}

task-preview .stop-working-dial {
  margin-left: 0;
  margin-right: -10px;
  position: relative;
}

task-preview .stop-working-dial:hover .stop-working-btn {
  background: #222 !important;
}

task-preview .stop-working-dial:hover .stop-working-btn md-icon {
  color: white;
}

task-preview .stop-working-dial md-fab-actions {
  position: absolute;
  top: 90%;
  padding-left: 40px;
}

task-preview .stop-working-dial md-fab-actions .md-fab {
  position: relative;
  padding-left: 8px;
  left: -20px;
}

task-preview .working-time {
  opacity: 0.6;
}

task-preview:hover .working-btn {
  visibility: visible;
}

task-preview .small-input-column > small {
  font-size: 12px;
  text-transform: uppercase;
}

task-preview .small-input-column input {
  opacity: 0.7;
}

task-preview .small-input-column div {
  font-size: 16px;
  opacity: 0.5;
}

.new-task-fake-input {
  background: white;
  border: solid 1px #ccc;
  min-height: 55px;
}

.new-task-fake-input input {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0 15px;
}

.new-task-fake-input md-input-container {
  margin-right: 15px;
}

.new-task-fake-input md-input-container.md-input-has-value {
  margin-bottom: -15px;
}

.new-task-fake-input .avatar-sm {
  max-width: 30px;
  max-height: 30px;
}

.fake-input {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0 15px;
}

.fake-input:focus {
  border-bottom: solid 2px #495c66;
}

user-badge h1 {
  margin-left: 10px;
}

[contenteditable].single-line {
  white-space: nowrap;
}

[contenteditable].single-line br {
  display: none;
}

[contenteditable].single-line * {
  display: inline;
  white-space: nowrap;
}

h1, h2, h3 {
  font-weight: 300;
}

.avatar {
  max-width: 60px;
  max-height: 60px;
  border-radius: 600px;
}

.avatar-sm {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.avatar-xs {
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
}

a, a:active, a:visited {
  color: #495c66;
  text-decoration: none;
}

.filters-bar {
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  padding: 0 10px;
}

.filters-bar input[type=text] {
  border: 0;
  height: 100%;
  padding: 0 10px;
  min-height: 50px;
  outline: none;
}

.filters-bar > * {
  margin: 0 5px;
}

.padding {
  padding: 20px;
}

.padding > md-card {
  margin-left: 0;
  margin-right: 0;
}

#loading-bar .bar {
  background: #495c66;
}

#loading-bar-spinner {
  left: 41px;
  top: 49px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: white !important;
  border-left-color: white !important;
  color: white !important;
}

.title-input {
  font-size: 32px;
  background: transparent;
  border: 0;
  border-bottom: solid 2px transparent;
  outline: none;
}

.title-input:hover {
  border-bottom: solid 2px #ccc;
}

.title-input:focus {
  border-bottom: solid 2px #495c66;
}

.transition-300, files-display .file-preview, files-display .file-preview a, files-display .file-preview a[download] md-icon, .title-input, #change-avatar-area .change-avatar-btn {
  transition: all 300ms ease !important;
}

.transition-1000 {
  transition: all 1s ease;
}

.text-accent {
  color: #495c66;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade, task-preview .small-input-column > small {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.no-bg {
  background: transparent;
}

.no-margin {
  margin: 0;
}

.bg-cover, #change-avatar-area {
  background-size: cover;
  background-position: center center;
}

.bg-danger {
  background: rgba(255, 0, 0, 0.1);
}

.bg-success {
  background: rgba(0, 128, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

#change-avatar-area {
  min-width: 250px;
  min-height: 250px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 250px;
  position: relative;
  margin-bottom: 50px;
}

#change-avatar-area .change-avatar-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 250px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  cursor: pointer;
}

#change-avatar-area:hover .change-avatar-btn {
  opacity: 1;
}

.login-project-title {
  color: #495c66;
  font-size: 50px;
  font-weight: 700;
  opacity: 0.9;
  letter-spacing: 1px;
  text-transform: uppercase;
}

teams-list {
  font-family: 'Work Sans', sans-serif;
}

teams-list .page-title {
  padding: 15px 15px 0px 15px;
}

teams-list .page-title h1 {
  margin: 15px;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

teams-list input[type=text] {
  border: 0;
  outline: none;
  font-size: 16px;
}

teams-list input[type=text]:focus {
  border-bottom: solid 1px #495c66;
}

teams-list md-card-content {
  padding-top: 5px;
}

teams-list md-select {
  margin: 10px 0px;
  width: 100%;
}

teams-list #team-filters {
  margin-top: 16px;
}

.search-by {
  padding: 10px;
}

.search-by md-input-container {
  margin: 10px 5px 5px 5px;
}

ul {
  list-style-type: none;
}

ul li.pagination-item {
  font-size: 16px;
  padding: 0;
  margin-left: 0;
  color: #495c66;
  background: #eee;
  border: 0;
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  float: left;
  margin: 10px;
}

ul li:hover {
  background: #222;
  color: white;
}

.page-title {
  padding: 15px 15px 0px 15px;
}

.page-title h1 {
  margin: 15px;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.organization-detail {
  margin-bottom: 25px;
}

.my-card {
  margin-bottom: 25px;
}

.comment {
  border-bottom: 1px solid #ccc;
}

.comment .comment-content {
  font-size: 12px;
  line-height: 18px;
  color: #999;
  padding: 15px 0 15px 0;
}

.organization-interaction {
  border-bottom: 1px solid #ccc;
  padding: 10px 0 0 0;
  line-height: 18px;
}

.organization-interaction:hover {
  background-color: #fafafa;
}

.organization-interaction:hover .actions {
  visibility: visible;
}

.organization-interaction p:first-of-type {
  font-size: 14px;
  color: #4a4a4a;
}

.organization-interaction span {
  font-size: 12px;
  color: #8a8a8a;
}

.organization-interaction .actions {
  visibility: hidden;
}

.organization-tag-list .entry {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 26px;
  border-bottom: 1px solid #eee;
}

.quick-add input, .quick-add md-select {
  margin-bottom: 20px;
}

.prospector-table .header {
  font-size: 12px;
  color: #bbb;
  padding: 20px 15px 20px 15px;
  border-bottom: 1px solid #e0e0e0;
}

.prospector-table .body {
  cursor: pointer;
  padding: 10px;
  font-size: 13px;
  border-bottom: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  padding: 15px;
}

.prospector-table .body:hover {
  background-color: #DBDBDB;
}

.prospector-table .center {
  text-align: center;
}

.prospector-table .flush-left {
  text-align: left;
}

.prospector-table .flush-right {
  text-align: right;
}

.prospector-table .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.industry-characterization {
  font-size: 13px;
  line-height: 18px;
  overflow-y: scroll;
}

.autocompletes-dialog {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 750px;
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 25px;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 22px;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}

.autocompletes-dialog .name {
  font-size: 22px;
  color: #4a4a4a;
}

.autocompletes-dialog img {
  display: block;
  padding: 25px 0;
  text-align: center;
  max-width: 100%;
}

.ranking-item {
  width: 70px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}

.site-visits p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  font-size: 13px;
  color: #4a4a4a;
  padding-bottom: 5px;
}

.workspace-selector-icon {
  color: #4a4a4a !important;
  font-size: 18px !important;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
}

